"use strict";
exports.__esModule = true;
exports.isMP4 = exports.isPhoneWithPrefix = exports.isEmail = exports.hasValue = void 0;
exports.hasValue = function (value) {
    return value ? !!value.trim() : !!value;
};
exports.isEmail = function (value) {
    var re = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    return re.test(String(value).toLowerCase());
};
exports.isPhoneWithPrefix = function (value) {
    var re = /^[0-9-+]+ [0-9-]+$/g;
    return re.test(String(value).toLowerCase());
};
exports.isMP4 = function (url) {
    var regExp = /\.(mp4)$/i;
    var videoPattern = new RegExp(regExp);
    return videoPattern.test(url);
};
exports["default"] = {};
